import { get, post } from './client';

const BASE_URL = process.env.REACT_APP_FULLSEND_URL ?? 'https://dev-api.degenfullsend.com/v1';
const DEFAULT_AGREEMENT_ID = 'DFC_AFFILIATE_AGREEMENT_2024';

export const getSignedAgreements = async (sortBy, Authorization = '') => {
  const res = await get(
    `${BASE_URL}/agreements/${DEFAULT_AGREEMENT_ID}/signatures?sortBy=${sortBy}`,
    {
      Authorization,
    }
  );
  console.log(res);
  return res?.payload;
};

export const createSignedAgreement = async (signedFor, signature, type, Authorization = '') => {
  const res = await post(
    `${BASE_URL}/agreements/${DEFAULT_AGREEMENT_ID}/signatures`,
    { signature, signedFor, type },
    { Authorization }
  );
  console.log(res);
  return res?.data?.payload;
};

export const getAuthorizedSignees = async (Authorization = '') => {
  const res = await get(`${BASE_URL}/authorized-signees`, {
    Authorization,
  });
  console.log(res);
  return res?.payload;
};

export const authorizeSigner = async (signerId, Authorization = '') => {
  const res = await post(`${BASE_URL}/signing-authorizations`, { signerId }, { Authorization });
  return res?.data?.payload;
};
