import { DateTime } from 'luxon';
import { formatDate } from '../../utils/helpers';

const LastSignedCard = ({ signedAgreement }) => {
  return (
    <div className={'alert alert-primary mb-3'}>
      <h6 className="alert-heading">Terms accepted!</h6>
      <div className="d-grid">
        <small className="mb-1">
          <span className="badge bg-primary">Effective</span>{' '}
          {formatDate(DateTime.fromISO(signedAgreement.date).toISO())} to{' '}
          {formatDate(DateTime.fromISO(signedAgreement.date).plus({ days: 30 }).toISO())}.
        </small>
        <small className="mb-0">
          <span className="badge bg-primary">Signed by</span> {signedAgreement.signedBy.slice(0, 4)}
          ...{signedAgreement.signedBy.slice(-4)}
        </small>
      </div>
    </div>
  );
};

export default LastSignedCard;
