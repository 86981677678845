import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { formatDate } from '../../utils/helpers';

const RecentSignedAgreements = ({ signedAgreements }) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <a href="#" className="" onClick={handleOpenModal}>
        Past Signatures ({signedAgreements?.length})
      </a>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Past Signatures</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-scroll">
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th scope="col">
                  <small>Valid Til</small>
                </th>
                <th scope="col">
                  <small>Signed On</small>
                </th>
                <th scope="col">
                  <small>Signature For</small>
                </th>
              </tr>
            </thead>
            <tbody>
              {signedAgreements.map((signedAgreement, i) => (
                <tr key={i}>
                  <td>
                    <small>
                      {formatDate(
                        DateTime.fromISO(signedAgreement.date).plus({ days: 30 }).toISO()
                      )}
                    </small>
                  </td>
                  <td>
                    <small>{formatDate(signedAgreement.date)}</small>
                  </td>
                  <td>
                    <small>
                      {signedAgreement.acceptedBy.slice(0, 4)}....
                      {signedAgreement.acceptedBy.slice(
                        signedAgreement.acceptedBy.length - 4,
                        signedAgreement.acceptedBy.length
                      )}{' '}
                      <a
                        href="#"
                        onClick={() => {
                          navigator.clipboard.writeText(signedAgreement.acceptedBy);
                        }}
                      >
                        <i className="fa-solid fa-copy"></i>
                      </a>
                    </small>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RecentSignedAgreements;
