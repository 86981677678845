import {
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  // Transaction,
  TransactionMessage,
  VersionedTransaction,
} from '@solana/web3.js';
import { getSolanaProvider } from './solana';
export const AUTH_WALLET_ID = new PublicKey('4UhFv9fbJtUYbzaqZPu5gbg8mreD52TUE8Uk7w5NnJx7');

const AUTH_AMOUNT = 0.00001;

export const signTransfer = async wallet => {
  const provider = getSolanaProvider(wallet);
  const {
    value: { blockhash },
  } = await provider.connection.getLatestBlockhashAndContext();
  const instructions = [
    SystemProgram.transfer({
      fromPubkey: provider.wallet.publicKey,
      toPubkey: AUTH_WALLET_ID,
      lamports: +(AUTH_AMOUNT * LAMPORTS_PER_SOL).toFixed(0), //Investing 1 SOL. Remember 1 Lamport = 10^-9 SOL.
    }),
  ];
  const messageV0 = new TransactionMessage({
    payerKey: wallet.publicKey,
    recentBlockhash: blockhash,
    instructions,
  }).compileToLegacyMessage();
  const versionedTransaction = new VersionedTransaction(messageV0);
  const vx = await wallet.signTransaction(versionedTransaction);
  return await provider.connection.sendTransaction(vx, {
    maxRetries: 3,
  });
};
