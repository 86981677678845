export const TermsOfServiceDetail = {
  id: 'tos',
  name: 'Terms of Service',
  icon: 'fa-file-alt',
  path: '/tos',
  selectImageUrl: 'https://i.imgur.com/LvijaIn.png',
};

export const Pages = [TermsOfServiceDetail];

export const LEDGER_KEY = 'LEDGER_KEY';
