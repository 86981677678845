import { DateTime } from 'luxon';
import { createContext, useContext, useState } from 'react';
import { authorize, authorizeViaTx, getNonce } from '../apis/auth.api';
import { authTransfer } from '../rpc/authTransfer.rpc';
import { signTransfer } from '../rpc/signTransfer.rpc';
import { getCurrentBalance } from '../rpc/balance.rpc';
import { loadState, saveState } from '../utils/localStorage';
import { useWallet } from '@solana/wallet-adapter-react';
import { normalizeSignedMessage } from '../utils/helpers';

const getHash = async nonce => {
  const prefixString = `I am signing my one-time nonce: ${nonce}`;
  const message = new TextEncoder().encode(prefixString);
  return message;
};

const getSignHash = async () => {
  const prefixString = `I acknowledge & accept the Degen Fat Cats affiliate campaign on ${DateTime.utc().toFormat(
    'LLL dd, yyyy'
  )}.`;
  const message = new TextEncoder().encode(prefixString);
  return message;
};

const AuthorizationContext = createContext({
  auth: null,
  signIn() {},
  signInViaLedger() {},
  signOut() {},
  signNonce() {},
  isExpired() {},
  signViaTransfer() {},
});

export const useAuth = () => {
  return useContext(AuthorizationContext);
};

const AuthorizationProvider = props => {
  const [auth, setAuth] = useState(loadState());
  const wallet = useWallet();
  const handleSaveAuth = data => {
    saveState(data);
    setAuth(data);
  };

  const signIn = async (walletId = wallet?.publicKey?.toString(), referral) => {
    const expired = DateTime.fromSeconds(auth?.exp ?? 0) < DateTime.utc().plus({ minutes: 150 });
    if (!auth || expired || auth?.username !== walletId) {
      if (!wallet.signMessage || !wallet.publicKey) {
        alert('Cannot sign message, please try again or try another wallet!');
        return;
      }

      const { nonce } = await getNonce(walletId, referral);
      const signature = await wallet.signMessage(await getHash(nonce));
      const authorization = await authorize(walletId, normalizeSignedMessage(signature));
      handleSaveAuth(authorization);
      return authorization;
    }

    return auth;
  };

  const signInViaLedger = async wallet => {
    const expired = DateTime.fromSeconds(auth?.exp ?? 0) < DateTime.utc().plus({ minutes: 150 });
    if (!auth || expired || auth?.username !== wallet?.publicKey?.toString()) {
      if (!(await getCurrentBalance(wallet))) {
        alert('0.001 SOL is needed to sign.');
        return;
      }

      const tx = await authTransfer(wallet);
      const authorization = await authorizeViaTx(wallet?.publicKey?.toString(), tx);
      handleSaveAuth(authorization);
      return authorization;
    }

    return auth;
  };

  const signOut = () => handleSaveAuth(null);

  const signNonce = async () => {
    if (!wallet.signMessage || !wallet.publicKey) {
      await wallet.connect();
    }
    const signature = await wallet.signMessage(await getSignHash());
    return normalizeSignedMessage(signature);
  };

  const signViaTransfer = async () => {
    if (!(await getCurrentBalance(wallet))) {
      alert('0.001 SOL is needed to sign.');
      return;
    }
    const tx = await signTransfer(wallet);
    return tx;
  };

  const isExpired = () => {
    const expired = DateTime.fromSeconds(auth?.exp ?? 0) < DateTime.utc().plus({ minutes: 150 });
    return !auth || expired || wallet.publicKey?.toString() !== auth?.username;
  };

  return (
    <div>
      <AuthorizationContext.Provider
        value={{ auth, signIn, signInViaLedger, signOut, signNonce, isExpired, signViaTransfer }}
      >
        {props.children}
      </AuthorizationContext.Provider>
    </div>
  );
};

export { AuthorizationContext, AuthorizationProvider };
