// PrivateRoute.js
import { useWallet } from '@solana/wallet-adapter-react';
import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth.context';
// import NavBar from './shared-components/NavBar';

const PrivateRoute = () => {
  const wallet = useWallet();
  const { auth, isExpired } = useAuth();

  if (!auth || isExpired() || auth.username !== wallet.publicKey?.toString()) {
    return <Navigate to="/login" replace />;
  }

  if (window.location.pathname === '/') {
    return <Navigate to="/tos" replace />;
  }

  return (
    <main className="d-flex flex-nowrap justify-content-center my-auto align-items-sm-center">
      {/* <NavBar /> */}
      <Outlet />
    </main>
  );
};

export default PrivateRoute;
