// src/components/ConnectWalletPrompt.js

import React from 'react';

const IMAGES = [
  'https://i.imgur.com/rq78rvr.png',
  'https://i.imgur.com/fMMZafs.png',
  'https://i.imgur.com/eonJNix.png',
  'https://i.imgur.com/v8CAH5R.png',
];

const bgImg = IMAGES[Math.floor(Math.random() * IMAGES.length)];

const ConnectWalletPrompt = ({ onRequestConnectWallet }) => {
  return (
    <div className="container-fluid">
      <div className="min-vh-100 bg-100 row">
        <div className="d-none d-lg-block position-relative col-6">
          <div
            className="bg-holder"
            style={{
              backgroundImage: `url("${bgImg}")`,
              backgroundPosition: '50% 30%',
            }}
          />
        </div>
        <div className="px-sm-0 align-self-center mx-auto py-5 col-md-6 col-sm-10">
          <div className="g-0 justify-content-center row">
            <div className="col-xxl-6 col-xl-8 col-lg-9">
              <div className="card border-0">
                <div className="p-4 mx-auto card-body">
                  <div className="d-flex align-items-center">
                    <div className="text-center">
                      <img
                        className="d-block mx-auto mb-4 shadow rounded-circle"
                        src="https://i.imgur.com/menWcho.png"
                        alt="sent"
                        width={100}
                      />
                      <button
                        onClick={onRequestConnectWallet}
                        className="wallet-adapter-button wallet-adapter-button-trigger"
                        tabIndex={0}
                        type="button"
                      >
                        Select Wallet
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectWalletPrompt;
