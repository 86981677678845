import React from 'react';
import { Card } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { formatDate } from '../../utils/helpers';

const now = DateTime.utc();

const AffiliateTermsCard = () => {
  return (
    <Card className="affiliate-terms-card mb-3">
      <Card.Body>
        <div className="terms-content">
          <div>
            <p>
              <strong>Degen Fat Cats Affiliate Terms &amp; Conditions</strong>
            </p>
            <p>
              Thank you for agreeing to participate in Degen Fat Cats' affiliate campaign (the "
              <strong>Campaign</strong>"). We are looking forward to working with you. These terms
              and conditions (the "<strong>Agreement</strong>"), dated as of{' '}
              <code>{formatDate(now.toISO())}</code>, (the “<strong>effective date</strong>”), sets
              out the agreement between Degen Fat Cats ("Degen Fat Cats" or "<strong>we</strong>")
              and you for your participation in the Campaign.
            </p>
            <ol>
              <li>
                Term; Exclusivity. This Agreement is effective from the date of the Agreement, until
                the end of the current calendar year ("<strong>Term</strong>").&nbsp;
              </li>
              <li>
                Social Media Posts. During the Term, you agree to publish the number of social media
                posts ("<strong>Posts</strong>") on the specific social media platforms identified
                in Exhibit A. The posts will conform to the specifications and instructions outlined
                in Exhibit A. We will provide you with the necessary briefing materials, so you can
                create Posts that achieve the goals of the Campaign (the "
                <strong>Campaign Materials</strong>"). All Posts will meet the following
                requirements:
              </li>
              <ol>
                <li>
                  Your Posts must comply with the Federal Trade Commission's (the "
                  <strong>FTC</strong>") Guides Concerning Endorsements and Testimonials ("
                  <strong>Endorsement Guides</strong>"), for which we provide you a link in Exhibit
                  B. You agree to participate in any training we require on our Social Media
                  Endorsement Policy which is designed to ensure compliance with the Endorsement
                  Guides.&nbsp;
                </li>
                <li>
                  Although we want Posts to be authentic, Posts should only include factual
                  statements about Degen Fat Cats and our products which you know for certain to be
                  true and Degen Fat Cats can prove or verify.&nbsp;
                </li>
                <li>Posts will reflect your own honest opinions, beliefs, and experiences.</li>
                <li>Posts will be original and created solely by you.</li>
                <li>
                  Posts will not include the intellectual property of other parties, including any
                  third-party music, photographs, artwork, trademarks, logos, or slogans.
                </li>
                <li>
                  Posts will not include any person, or personally identifiable information about
                  anyone, other than you unless you receive our prior written approval and have the
                  persons at issue sign a release provided by us.
                </li>
                <li>Posts will comply with the rules of the applicable social media platforms.</li>
                <li>
                  Posts will comply with our standards of conduct set out in Exhibit B and any other
                  policies we provide you.
                </li>
                <li>
                  Posts will comply with all applicable laws, rules, and regulations, including
                  without limitation.
                </li>
              </ol>
              <li>
                Schedule. With respect to each Post due during the Term, you will publish each Post
                on a timely basis according to the schedule set out in Exhibit A.
              </li>
              <li>
                Monitoring Posts. You understand that we will be monitoring Posts for compliance
                with this Agreement. We have the right to address noncompliant Posts by taking any
                of the following actions alone or in combination:
              </li>
              <ol>
                <li>Requiring you to fix the Post.</li>
                <li>Fixing the Post ourselves or through one of our agencies.</li>
                <li>Withholding payment of [an installment of] the Fee.</li>
                <li>Terminating the Agreement under Paragraph 11(a) for a material breach.</li>
              </ol>
              <li>Ownership; Grant of Rights.</li>
              <ol>
                <li>
                  Degen Fat Cats is and will be the sole and exclusive owner of all right, title,
                  and interest in and to the Posts, including all copyrights and other intellectual
                  property rights therein. We will own each Post as a work made for hire as defined
                  in Section 101 of the Copyright Act of 1976. To the extent any Post does not
                  qualify as, or otherwise fails to be, work made for hire, you hereby (a) assign,
                  transfer, and otherwise convey to us, irrevocably and in perpetuity, throughout
                  the universe, all right, title, and interest in and to the Posts, including all
                  copyrights and other intellectual property rights in them; and (b) irrevocably
                  waive any and all claims you may now or hereafter have in any jurisdiction to
                  so-called "moral rights" with respect to the Posts.
                </li>
                <li>
                  We hereby grant you a limited license to use the Posts solely to promote Degen Fat
                  Cats.
                </li>
              </ol>
              <li>
                Payment. By agreeing to the terms of this agreement, you may be eligible to receive
                a one-time fee, once per week – or at a new frequency to be determined in the future
                – discretionary payment directly to your Solana-native wallet (the "
                <strong>payments</strong>") for completing and publishing all the Posts that comply
                with the terms of this Agreement. In order to remain eligible to receive payments,
                you are required to remain “active” by connecting your Solana wallet to our
                dedicated affiliate site every 30 days and signing in. If you fail to remain active
                over a rolling 30-day period, you will be deemed “inactive”. In the event you are
                deemed inactive, we reserve the right to stop payments to you until such action is
                taken by you to qualify as active. So long as you remain active, payments will
                continue to be deposited into your wallet until the expiration of the Term of this
                Agreement, or other termination as allowed by this Agreement. If you do not accept
                the terms of the agreement herein, no such payments will be made.
              </li>
              <li>
                Confidentiality. You understand that you may be exposed to information about Degen
                Fat Cats and ideas that may not have been disclosed to the public (collectively, the
                "<strong>Confidential Information</strong>"). You agree to maintain the
                confidentiality of all Confidential Information disclosed to you (or which otherwise
                becomes available to you) in connection with the Campaign and will hold all
                Confidential Information in strict confidence. You further agree to refrain from
                disclosing or using Confidential Information for any purpose other than
                participating in the Campaign. The obligation to maintain the confidentiality of
                Confidential Information shall survive termination and continue for 3 years.&nbsp;
              </li>
              <li>
                Representations and Warranties. By providing the Posts to us, you represent and
                warrant that the Posts:&nbsp;
              </li>
              <ol>
                <li>are your sole and original creation;</li>
                <li>
                  have not been, and prior to our publication of them, will not be, published or
                  otherwise made publicly available, in whole or in part;
                </li>
                <li>are not libelous or otherwise defamatory; and</li>
                <li>
                  do not, and our use or them will not, infringe or otherwise violate any right of
                  any third party, including any copyright, trademark, patent, trade secret, or
                  other intellectual property right, or any right of publicity or privacy.
                </li>
              </ol>
              <li>
                Indemnification. You agree to indemnify, defend, and hold harmless Degen Fat Cats
                and our affiliates from and against any claims, judgments, damages, liabilities,
                settlements, losses, costs, and expenses, including attorneys' fees and
                disbursements, arising from, or relating to any breach by you of your
                representations or warranties hereunder.
              </li>
              <li>Termination.</li>
              <ol>
                <li>
                  You may terminate this Agreement if we commit a material breach of this Agreement
                  and fail to cure the breach with 30 days of receiving notice of the breach from
                  you. We may terminate this Agreement if you commit a material breach of this
                  Agreement and fail to cure the breach with 24 hours of receiving notice of the
                  breach from us. To avoid any doubt, failure of a Post to comply with the
                  requirements of Section 2, failure to post for long periods at a time, are
                  material breaches.
                </li>
                <li>
                  Degen Fat Cats may terminate this Agreement immediately on written notice to you
                  if:
                </li>
                <ol>
                  <li>
                    you commit or are alleged to have committed any criminal act or other act
                    involving moral turpitude, drugs, or felonious activities; or
                  </li>
                  <li>
                    you commit any act or become involved in any situation or occurrence which
                    brings you into public disrepute, contempt, scandal, or ridicule, or which
                    shocks or offends the community or any group or class thereof, or which reflects
                    unfavorably upon Degen Fat Cats or reduces the commercial value of our
                    association with you; or
                  </li>
                  <li>
                    the company faces litigation or other legal action whereby the associated costs
                    of such actions are unable to be covered in full by capital allocated to the
                    contingency fund.&nbsp;
                  </li>
                </ol>
              </ol>
              <li>Relationship of the Parties.</li>
              <ol>
                <li>
                  You understand that you are an independent contractor of Degen Fat Cats, and this
                  Agreement does not create any association, partnership, joint venture, employee,
                  or agency relationship between you and us for any purpose. You have no authority
                  (and will not hold yourself out as having authority) to bind Degen Fat Cats and
                  will not make any agreements or representations on our behalf without our prior
                  written consent.
                </li>
                <li>
                  You understand that you are a contracted affiliate and are not entitled to any
                  share of the company’s revenues or profits
                </li>
                <li>
                  We are not responsible for withholding or paying any income, payroll, Social
                  Security, or other taxes, making any insurance contributions, including
                  unemployment or disability, or obtaining worker's compensation insurance on your
                  behalf. You are solely responsible for all such taxes and contributions, including
                  penalties and interest. You are not eligible under this Agreement to participate
                  in any of our employee benefits, such as time off, medical, profit sharing, or
                  retirement benefits.
                </li>
              </ol>
              <li>Miscellaneous.</li>
              <ol>
                <li>
                  This Agreement is personal to you. You will not assign or otherwise transfer any
                  of your rights, or delegate, subcontract, or otherwise transfer any of your
                  obligations or performance, under this Agreement. Any attempt to assign, delegate,
                  or transfer in violation of this paragraph is void. Degen Fat Cats may freely
                  assign or otherwise transfer all or any of its rights, or delegate or otherwise
                  transfer all or any of its obligations or performance, under this Agreement. This
                  Agreement is binding upon and inures to the benefit of the parties and their
                  respective permitted successors and assigns.
                </li>
                <li>
                  This Agreement is governed by and construed in accordance with the laws of Wyoming
                  without giving effect to any conflict of laws provisions that would result in the
                  application of the laws of a different jurisdiction. Each party agrees to
                  institute any legal suit, action, or proceeding arising out of this Agreement or
                  the Posts in the federal or state courts in each case located in Wyoming.&nbsp;
                </li>
                <li>
                  If any provision of this agreement is illegal or unenforceable under applicable
                  law, the remainder of the provision will be amended to achieve as closely as
                  possible the effect of the original term and all other provisions of this
                  agreement will continue in full force and effect. This agreement contains the
                  entire agreement between you and Degen Fat Cats and supersedes any oral or written
                  statements made by or to you in connection with the Campaign and the Posts. This
                  agreement may not be modified except by a written agreement that is signed by an
                  authorized representative of Degen Fat Cats.
                </li>
                <li>
                  We reserve the right to modify this contract and any related policies to ensure
                  compliance with applicable laws and regulations
                </li>
              </ol>
            </ol>
            <p>
              <br />
            </p>
            <p>
              <strong>EXHIBIT A</strong>
            </p>
            <p>
              <strong>Social Media Platforms; Requirements for Posts</strong>
            </p>
            <p>
              With respect to promotional messages, photos, or other communications made on social
              media platforms about Degen Fat Cats, all influencers must adhere to the following
              expectations:
            </p>
            <ul>
              <li>
                Follow all associated project accounts with notifications on. Currently
                <a href="https://twitter.com/degencoinflip">
                  https://twitter.com/degencoinflip
                </a>{' '}
                &amp;{' '}
                <a href="https://twitter.com/degenfatcats">https://twitter.com/degenfatcats</a>
              </li>
              <li>
                Engage in social media posts by commenting, liking, retweeting, and/or bookmarking
                posts
              </li>
              <li>
                Be a representative of Degen Fat Cats by having a message and link to one of our
                games in your Twitter and/or Discord profile, e.g.:
              </li>
              <ul>
                <li>“Double your sol at https://degencoinflip.com"</li>
              </ul>
              <li>Engage (like, comment, retweet) with big product releases and events</li>
              <li>
                Subscribe to our Discord channel and periodically engage by interacting with the
                community, e.g., engaging with other members, supporting announcements with likes
                and emojis
              </li>
              <li>Participate in events every quarter</li>
              <li>Share through word of mouth weekly</li>
            </ul>
            <p>
              <strong>Exhibit b</strong>
            </p>
            <p>
              <strong>Standards of Conduct</strong>
            </p>
            <p>
              With respect to promotional messages, photos, or other communications made on social
              media platforms about Degen Fat Cats, all influencers must adhere to the following
              standards:
            </p>
            <ul>
              <li>
                You must comply with the Federal Trade Commission's (the "<strong>FTC</strong>")
                Guides Concerning Endorsements and Testimonials
                (https://www.ecfr.gov/current/title-16/chapter-I/subchapter-B/part-255), including
                making:
              </li>
              <ul>
                <li>statements that reflect your honest beliefs, opinions, and experiences; and</li>
                <li>
                  clear and conspicuous disclosure about your connection to us in all of your posts.
                </li>
              </ul>
              <li>
                To better understand your responsibilities under the Endorsement Guides, you must
                review:&nbsp;
              </li>
              <ul>
                <li>
                  The FTC's Endorsement Guides: What People Are Asking
                  (https://www.ftc.gov/business-guidance/resources/ftcs-endorsement-guides-what-people-are-asking).&nbsp;
                </li>
                <li>
                  FTC: The Do's and Don'ts for Social Media Influencers
                  (https://www.ftc.gov/news-events/news/press-releases/2017/09/csgo-lotto-owners-settle-ftcs-first-ever-complaint-against-individual-social-media-influencers).
                </li>
                <li>
                  FTC: Disclosures 101 for Social Media Influencers
                  (https://www.ftc.gov/business-guidance/resources/disclosures-101-social-media-influencers).&nbsp;
                </li>
                <li>
                  FTC: Do you endorse things on social media? (https://www.ftc.gov/media/71405).
                </li>
              </ul>
              <li>You may not:</li>
              <ul>
                <li>
                  make deceptive or misleading claims about our products/services or our
                  competitors' products or services;
                </li>
                <li>
                  make any claims about our products/services or our competitors' products/services
                  that are not backed up by evidence;
                </li>
                <li>disclose any of our confidential information;</li>
                <li>disparage Degen Fat Cats or our brands/products/services;</li>
                <li>
                  engage in any communication that is defamatory or infringes upon the copyright,
                  trademark, privacy, publicity, or other intellectual property rights of others;
                </li>
                <li>offer for sale or solicit products on behalf of Degen Fat Cats;</li>
                <li>
                  make offensive comments that have the purpose or effect of creating an
                  intimidating or hostile environment;
                </li>
                <li>
                  post content that promotes bigotry, racism, or discrimination based on race,
                  gender, religion, nationality, disability, sexual orientation, or age;
                </li>
                <li>
                  use ethnic slurs, personal insults, obscenity, or other offensive language; and
                </li>
                <li>
                  make any comments or post any content that in any way promotes unsafe activities
                  that could lead to an unsafe situation involving Degen Fat Cats’ consumers or
                  other individuals.
                </li>
              </ul>
              <li>You must adhere to:</li>
              <ul>
                <li>
                  the posted policies, guidelines, and terms of use on any platform on which you
                  post content on behalf of Degen Fat Cats, understanding that any these platforms'
                  disclosure requirements about your connection to us do not necessarily satisfy FTC
                  disclosure requirements; and
                </li>
                <li>
                  any additional guidelines provided by Degen Fat Cats, such as
                  product/service/brand-specific program requirements and our Social Media
                  Endorsement Policy.
                </li>
              </ul>
              <li>
                You must not create fake followers or engagement on social media platforms, such as:
              </li>
              <ul>
                <li>buying followers;</li>
                <li>
                  using bots to grow audience size by automating account creation, following,
                  commenting, and liking; or
                </li>
                <li>post fake sponsored content.</li>
              </ul>
            </ul>
            If this Agreement reflects your understanding, please click the Sign & Accept button.
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AffiliateTermsCard;
