import { useEffect, useRef, useState } from 'react';
import Terms from './Terms';
import AffiliateTermsModal from './TermsModal';
import { useAuth } from '../../contexts/auth.context';
import { getSignedAgreements, createSignedAgreement } from '../../apis/fullsend.api';
import LastSignedCard from './LastSignedCard';
import { useWallet } from '@solana/wallet-adapter-react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { formatDate } from '../../utils/helpers';
import { DateTime } from 'luxon';
import AddAuthorizedSigner from './AuthorizeSigner';
import SelectAuthorizedSignerForm from './SelectAuthorizedSignerForm';
import RecentSignedAgreements from './RecentSignedAgreements';
import { loadState } from '../../utils/localStorage';
import { LEDGER_KEY } from '../../utils/constants';

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const TermsOfService = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [effectiveSignedAgreement, setEffectiveSignedAgreement] = useState(null);
  const [signedAgreements, setSignedAgreements] = useState(null);
  const [newlyCreatedSignature, setNewlyCreatedSignature] = useState(null);

  const [isLedger, setIsLedger] = useState(loadState(LEDGER_KEY));
  const [showToast, setShowToast] = useState(false);
  const [signedFor, setSignedFor] = useState(null);
  const { auth, signViaTransfer, signNonce } = useAuth();
  const wallet = useWallet();

  const toggleLedger = () => {
    setIsLedger(!isLedger);
  };

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();

    try {
      let signature;
      let type;
      if (!isLedger) {
        signature = await signNonce();
        type = 'non_ledger';
      } else {
        signature = await signViaTransfer();
        type = 'ledger';
        await delay(6900);
      }
      const data = await createSignedAgreement(signedFor, signature, type, authToken.current);
      setNewlyCreatedSignature(data);
      const effectiveResponse = await getSignedAgreements('EFFECTIVE', authToken.current);
      setEffectiveSignedAgreement(effectiveResponse[0]);

      setShowToast(true);

      fetchRecentData();
      fetchEffectiveData();
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  const onSelect = signerId => {
    setSignedFor(signerId);
  };

  const authToken = useRef(auth?.idToken);

  useEffect(() => {
    if (authToken.current !== auth?.idToken) authToken.current = auth?.idToken;
  }, [auth?.idToken]);

  useEffect(() => {
    wallet.connect();
  }, [wallet]);

  const fetchRecentData = async () => {
    const response = await getSignedAgreements('RECENT', authToken.current);
    setIsExpanded(!response?.length);
    setSignedAgreements(response);
  };
  const fetchEffectiveData = async () => {
    const response = await getSignedAgreements('EFFECTIVE', authToken.current);
    setEffectiveSignedAgreement(response[0]);
  };

  useEffect(() => {
    fetchRecentData();
    fetchEffectiveData();
  }, []);

  return (
    <>
      <ToastContainer position="bottom-start" className="p-3">
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={10000} autohide>
          <Toast.Body>
            Signed Successfully! Effective until{' '}
            {formatDate(DateTime.fromISO(newlyCreatedSignature?.date).plus({ days: 30 }).toISO())}.
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <div className="tos-container">
        <form className="form-signin" onSubmit={handleSubmit}>
          {effectiveSignedAgreement && (
            <LastSignedCard signedAgreement={effectiveSignedAgreement} />
          )}
          <div className="mb-4">
            <h1 className="h3 mb-3 font-weight-normal">Acknowledge terms</h1>
            <p>
              By participating in <code>Degen Fat Cat's affiliate campaign</code>, you agree to the{' '}
              <AffiliateTermsModal />:
            </p>
            <ul>
              <li>
                <small>
                  Signing the contract with your wallet allows you to be eligible for payments for
                  being an affiliate marketer for DFC.
                </small>
              </li>
              <li>
                <small>
                  You must show activity by signing in at least every 30 days to continue to be
                  eligible for payments (see section 6 for details).
                </small>
              </li>
              {!isExpanded && (
                <li>
                  <small>
                    <a href="#" onClick={() => setIsExpanded(true)}>
                      See Details <i className="fa fa-chevron-down" />
                    </a>
                  </small>
                </li>
              )}
            </ul>
          </div>
          {isExpanded && <Terms />}
          <button className="btn btn-lg btn-dark btn-block w-100" type="submit">
            {!loading && <>Sign & Accept</>}
            {loading && <i className="fa fa-circle-notch fa-spin" />}
          </button>
        </form>
        <small className="d-flex w-100 justify-content-evenly">
          <a href="#" onClick={toggleLedger}>
            {isLedger && (
              <>
                <i className="fa-solid fa-check text-decoration-underline"></i>&nbsp;
              </>
            )}
            Use Ledger
          </a>
          <SelectAuthorizedSignerForm
            signerId={signedFor}
            authToken={authToken.current}
            onSelect={onSelect}
          />
          {!signedFor?.length && <AddAuthorizedSigner authToken={authToken.current} />}
          {!signedFor?.length && !!signedAgreements?.length && (
            <RecentSignedAgreements signedAgreements={signedAgreements} />
          )}
        </small>
        <p className="my-3 text-muted text-center">© degenfatcats 2024</p>
      </div>
    </>
  );
};

export default TermsOfService;
