import React, { useState } from 'react';
import { Button, Modal, Form, ToastContainer, Toast } from 'react-bootstrap';
import { authorizeSigner } from '../../apis/fullsend.api';
import { isValidSolanaAddress } from '../../utils/helpers';

const AddAuthorizedSigner = ({ authToken }) => {
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const onClicked = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const signerId = event.target.signerId.value;

    if (!isValidSolanaAddress(signerId)) {
      alert('Invalid Solana address');
      return;
    }

    await authorizeSigner(signerId, authToken);
    setShowToast(true);
    handleClose();
  };

  return (
    <>
      <ToastContainer position="bottom-start" className="p-3">
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={10000} autohide>
          <Toast.Body>
            <h6 className="m-0">Successfully added Authorized Signer!</h6>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <a href="#" className="" onClick={onClicked}>
        Add Signer
      </a>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="signerId">
              <Form.Label>Add Authorized Signer</Form.Label>
              <Form.Control type="text" placeholder="Enter another wallet address" required />
            </Form.Group>
            <Button variant="dark" className="mt-3 w-100 d-block" type="submit">
              Authorize Signer
            </Button>
            <hr />
            <h5>
              What does "<b>Authorize Signer</b>" do?
            </h5>
            <p className="mb-0">
              This function is meant for holders with cats in cold wallets (e.g Ledger).
              <br />
              <br />
              Enter <b>another wallet address</b> above, click 'Authorize Signer', and allow{' '}
              <b>another wallet address</b> you own to sign on the (this) cold wallet's behalf in
              the future.
            </p>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddAuthorizedSigner;
