import React, { useState, useEffect } from 'react';
import { Modal, ListGroup } from 'react-bootstrap';
import { getAuthorizedSignees } from '../../apis/fullsend.api';

const SelectAuthorizedSignerForm = ({ signerId, onSelect, authToken }) => {
  const [showModal, setShowModal] = useState(false);
  const [authorizedSigners, setAuthorizedSigners] = useState([]);

  const handleOpenModal = e => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSelectSigner = signerId => {
    onSelect(signerId);
    handleCloseModal();
  };

  useEffect(() => {
    const fetchAuthorizedSigners = async () => {
      try {
        const payload = await getAuthorizedSignees(authToken);
        setAuthorizedSigners(payload);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAuthorizedSigners();
  }, [authToken]);

  return (
    <>
      {!signerId?.length && !!authorizedSigners?.length && (
        <a href="#" onClick={handleOpenModal}>
          Sign For Another Wallet
        </a>
      )}
      {signerId && (
        <small className="d-flex justify-content-center mb-3">
          <i className="mx-auto">
            Signing for Wallet ({signerId.slice(0, 4)})
            <a href="#" className="ms-2" onClick={() => handleSelectSigner(null)}>
              <i className="fas fa-times" style={{ color: 'red' }}></i>
            </a>
          </i>
        </small>
      )}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Body className="text-center">
          <h5 className="mb-3">Select Wallet You Want To Sign For</h5>
          <ListGroup>
            {authorizedSigners.map(signer => (
              <ListGroup.Item
                active={signer.walletId === signerId}
                key={signer.walletId}
                action
                onClick={() => handleSelectSigner(signer.walletId)}
              >
                {signer.walletId}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SelectAuthorizedSignerForm;
