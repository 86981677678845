import { PublicKey } from '@solana/web3.js';
import { DateTime } from 'luxon';

export const formatDate = dateString => {
  const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = DateTime.fromISO(dateString, { zone: 'utc' });
  return date.setZone(USER_TIMEZONE).toLocaleString(DateTime.DATE_MED);
};

export const isValidSolanaAddress = addr => {
  let publicKey;
  try {
    publicKey = new PublicKey(addr);
    return PublicKey.isOnCurve(publicKey.toBytes());
  } catch (err) {
    return false;
  }
};

export const normalizeSignedMessage = sig => {
  if (sig.type === 'Buffer') return sig;
  return {
    type: 'Buffer',
    data: Object.keys(sig).map(key => sig[key]),
  };
};
