import { AnchorProvider } from '@project-serum/anchor';
import { Connection, clusterApiUrl } from '@solana/web3.js';

const URL = process.env.REACT_APP_RPC_URL || clusterApiUrl('devnet');
const DEFAULT_TIMEOUT_IN_MS = 120000;

export const ProviderTypes = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  FREE: 'free',
};

const rpcUrls = {
  [ProviderTypes.PRIMARY]: process.env.REACT_APP_PRIMARY_RPC_URL,
  [ProviderTypes.SECONDARY]: process.env.REACT_APP_SECONDARY_RPC_URL,
  [ProviderTypes.FREE]: process.env.REACT_APP_FREE_RPC_URL,
};

const getConnection = type => {
  const rpcUrl = rpcUrls[type] || URL;
  const connectionOptions = {
    commitment: 'confirmed',
  };
  return new Connection(rpcUrl, connectionOptions);
};

export const getSolanaProvider = (wallet, type = ProviderTypes.FREE) => {
  const connection = getConnection(type);
  const providerOptions = {
    commitment: connection.commitment,
    confirmTransactionInitialTimeout: DEFAULT_TIMEOUT_IN_MS,
    skipPreflight: true,
  };
  return new AnchorProvider(connection, wallet, providerOptions);
};
